import React from 'react';
import Modal from 'react-bootstrap/Modal';

import AnimatedLoader from '../animated-loader';
import styles from './modal.module.css';

const LoaderModal = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className={styles.modalWrapper}>
          <Modal className={styles.customModalBody} show={true} fullscreen={true}>
            <Modal.Body>
              <AnimatedLoader />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default LoaderModal;
