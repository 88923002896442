import { useEffect, useState } from 'react';

import LoaderModal from 'components/shared-components/modal-loader/modal-loader';

import usePrivacy from 'hooks/use-privacy';

import styles from './terms.module.css';

const TradingTermsAndConditions = () => {
  const { loading, getTradingTermsAndConditions } = usePrivacy();
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    getTradingTermsAndConditions((resp) => {
      setTerms(resp?.data?.description);
    });
  }, []);

  return (
    <>
      <LoaderModal loading={loading} />
      {terms && (
        <div className={styles.termsContainer} dangerouslySetInnerHTML={{ __html: terms }}></div>
      )}
    </>
  );
};

export default TradingTermsAndConditions;
