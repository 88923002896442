import api from 'interceptors';

let url = 'user';

const TermsAndPrivacyService = {};

TermsAndPrivacyService.getPrivacyPolicy = async () => {
  return api.get(`${url}/privacypolicy`);
};

TermsAndPrivacyService.getTermsAndConditions = async () => {
  return api.get(`${url}/termsandconditions`);
};

TermsAndPrivacyService.getTradingTermsAndConditions = async () => {
  return api.get(`${url}/tradingtermsandconditions`);
};

export default TermsAndPrivacyService;
