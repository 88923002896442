import api from 'interceptors';

const AuthenticationService = {};

AuthenticationService.sendOTP = async (data) => {
  return api.post(`/auth/request-otp`, data);
};

AuthenticationService.validateOTP = async (data) => {
  return api.post(`/auth/verify-otp`, data);
};

AuthenticationService.sendSmsOTP = async (body) => {
  return api.post(`/auth/request-otp`, body);
};

AuthenticationService.validateSmsOTP = async (body) => {
  return api.post(`/auth/verify-otp`, body);
};

AuthenticationService.userLogin = async (data) => {
  return api.post(`/auth/login`, data);
};

AuthenticationService.userRegister = async (data) => {
  return api.post(`/auth/register`, data);
};

AuthenticationService.getForgetPasswordLink = async (data) => {
  return api.post(`/auth/request-otp`, data);
};

AuthenticationService.verifyResetPasswordOTP = async (data) => {
  return api.post(`auth/verify-otp`, data);
};

AuthenticationService.resetPassword = async (data) => {
  return api.post(`auth/reset-password`, data);
};

AuthenticationService.registerElectricianUser = async (body) => {
  return api.post(`auth/user/register/electrician`, body);
};

AuthenticationService.createUser = async (body, token) => {
  return api.post(`auth/user/create`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};
AuthenticationService.registerSolarCompanyUser = async (body, reselleId) => {
  return api.post(`auth/user/register/solarcompany/${reselleId}`, body);
};

AuthenticationService.sendInvite = async (body, token) => {
  return api.post(`auth/user/invite`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.createPassword = async (body, token) => {
  return api.post(`auth/user/create-password`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.updatePassword = async (body, token) => {
  return api.post(`auth/update-password`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.requestUpdateEmail = async (body, token) => {
  return api.post(`auth/user/request-update-email`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.updateEmail = async (body, token) => {
  return api.post(`auth/user/update-email`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.updateEmailWithKey = async (body) => {
  return api.post(`auth/user/update-email-with-key`, body);
};

AuthenticationService.decryptKey = async (body, token) => {
  return api.post(`auth/user/decrypt/emailupdate`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.getElectricianStatesDropdown = async () => {
  return api.get(`auth/user/state/list`);
};

AuthenticationService.getConnectedDevicesList = async (userId, token) => {
  return api.get(`auth/connecteddevices/list?pUserId=${userId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.logoutDeviceByIdOrAll = async (body, token) => {
  return api.post(`auth/connecteddevices/logout`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.requestUpdateMobileNo = async (body, token) => {
  return api.post(`auth/user/request-update-mobile-no`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.updateMobileNo = async (body, token) => {
  return api.post(`auth/user/update-mobile-no`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.updateMobileNoWithKey = async (body) => {
  return api.post(`auth/user/update-mobile-no-with-key`, body);
};

AuthenticationService.decryptMobileNoKey = async (body, token) => {
  return api.post(`auth/user/decrypt/mobile-no-update`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.sendInstallerInvite = async (body, token) => {
  return api.post(`auth/user/invite/installer/not-found`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.switchUserType = async (body, token) => {
  return api.post(`auth/user/switch-user-type`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.logoutUser = async (body, token) => {
  return api.post(`auth/connecteddevices/logout`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.checkSolarCompanyExist = async (body, token) => {
  return api.post(`auth/check/solarcompany/exist`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

AuthenticationService.getThemeByResellerId = async (resellerId) => {
  return api.get(`auth/theme/get/${resellerId}`);
};

export default AuthenticationService;
