export const APP_ROUTES = {
  public: {
    HOME: '/',
    FORGOT_PASSWORD: `/forgot-password`,
    AUTHENTICATION_CALLBACK: '/authentication/login-callback',
    SIGNUP: `/sign-up`,
    SIGNUP_SOLAR: `/sign-up-solar-retailer`,
    SIGNUP_ELECTRICIAN: `/sign-up-trade-specialist`,
    SIGNIN: `/sign-in`,
    USER_INVITED: '/user-invited',
    UPDATE_EMAIL: '/update-email',
    UPDATE_MOBILE_NO: '/update-mobile-no',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: `/terms-and-conditions`,
    TERMS_AND_PRIVACY_POLICY: `/terms-and-privacy-policy`,
    TRADING_TERMS_AND_CONDITIONS: `/trading-terms-and-conditions`
  },

  private: {
    HOME: '/',
    DASHBOARD: '/dashboard',
    PROFILE: (userTypeID, id) => `/profile/${userTypeID}/${id}`,
    INVITE_USER: '/invite-user',
    SENT_INVITES: '/sent-invites',
    REQUESTED_ACCESS_TYPES: '/requested-access-types',
    EDIT_PROFILE: '/edit-profile',
    CREATE_PROFILE: (userType, id) => `/create-profile/${userType}/${id}`,
    USERS: `/users`,
    MY_PORTALS: `/my-portals`,
    USER_TYPE: '/access-type',
    USER_ROLE: '/user-role',
    CREATE_USER_ROLE: '/create-user-role',
    EDIT_USER_ROLE: (id) => `/edit-user-role/${id}`,
    AUDITOR_GROUP: '/auditor-group',
    COMMODITY_LISTING: '/certificate-listing',
    ACCESS_DENIED: '/access-denied',
    REQUIREMENTS: '/requirements',
    PRICING_MANAGER: '/pricing-manager',
    PV_LIST: '/pv-list',
    INVERTER_LIST: '/inverter-list',
    BATTERY_STORAGE: '/battery-storage',
    INSTALLER_LIST: '/installers-list',
    SWH_LIST: '/swh-list',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: `/terms-and-conditions`,
    TRADING_TERMS_AND_CONDITIONS: `/trading-terms-and-conditions`,
    AUTHENTICATION_CALLBACK: '/authentication/login-callback',
    LOGOUT: '/logout'
  }
};
