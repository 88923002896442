import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';

import Header from 'components/shared-components/header/header';
import ProSidebar from 'components/shared-components/sidebar/pro-sidebar';

import styles from './applayout.module.css';

const Layout = ({ children, showSidebar = true }) => {
  return (
    <Row>
      <div className={styles.abc}>
        {showSidebar && (
          <div className={styles.sidebarSection}>
            <ProSidebar />
          </div>
        )}
        <div className={styles.appSection}>
          <div className={styles.header}>
            <Header />
          </div>
          {children}
        </div>
      </div>
    </Row>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
