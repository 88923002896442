import { createSlice } from '@reduxjs/toolkit';

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    user: null,
    assignedAccessTypes: []
  },
  reducers: {
    addUserProfile: (state, action) => {
      return {
        ...state,
        user: action.payload
      };
    },
    addAssignedAccessTypes: (state, action) => {
      return {
        ...state,
        assignedAccessTypes: action.payload
      };
    }
  }
});

export const { addUserProfile, addAssignedAccessTypes } = userProfileSlice.actions;
export default userProfileSlice.reducer;
