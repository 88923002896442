import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  streetTypes: [],
  postalDeliveryTypes: [],
  postalAddressTypes: [],
  unitTypes: [],
  invoicers: [],
  themes: []
};

const userDropdownSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    addUserDropdownList: (state, action) => {
      const invoicersList = action.payload.invoicers.map((item) => {
        return {
          ...item,
          name: `${item.firstName}`
        };
      });

      return {
        ...state,
        streetTypes: action.payload.streetTypes,
        postalDeliveryTypes: action.payload.postalDeliveryTypes,
        postalAddressTypes: action.payload.postalAddressTypes,
        unitTypes: action.payload.unitTypes,
        seRoles: action.payload.seRoles,
        themes: action.payload.themes,
        invoicers: invoicersList
      };
    }
  }
});

export const { addUserDropdownList } = userDropdownSlice.actions;
export default userDropdownSlice.reducer;
