import { APP_ROUTES } from 'config/app-routes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authToken } from 'redux/auth/authSlice';
import { setUser, setConvertToID, setPreviouslyLoggedIn } from 'redux/user/userSlice';

import useAuth from 'hooks/use-auth';

import showToast from 'utils/notificationToast/notificationToast';
import { clearPersistedStorage } from 'utils/utils';

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, logoutUser } = useAuth();

  const logout = useCallback(() => {
    logoutUser(
      {
        isPortal:true,
        deviceIdToLogout: '',
        allDeviceLogout: false
      },
      async () => {
        localStorage.clear('accessToken');
        localStorage.clear('persist:root');
        await clearPersistedStorage();
        navigate(APP_ROUTES.public.HOME, { replace: true });
        showToast('Logging out', 'success');
        dispatch(setUser(null));
        dispatch(authToken(null));
        dispatch(setConvertToID(null));
        dispatch(setPreviouslyLoggedIn(null));
      }
    );
  }, []);

  return { logout, loading };
};

export default useLogout;
