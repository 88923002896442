import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {}
};

export const editComplianceOfficer = createSlice({
  name: 'editComplianceOfficer',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    resetState: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { addPersonalDetail, resetState } = editComplianceOfficer.actions;

export default editComplianceOfficer.reducer;
