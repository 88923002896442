import api from 'interceptors';

const UserService = {};

const url = 'user';

UserService.getAll = async (token) => {
  return api.get(`${url}/usersfordropdown/2`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getUserById = async (id, userType, token) => {
  return api.get(`${url}/${id}/${userType}/userprofile/get`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getUserList = async (data, token) => {
  return api.post(`${url}/paginateduserlist/get`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getUserInvitesList = async (data, token) => {
  return api.post(`${url}/paginateduserinviteslist/get`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.deleteUserById = async (userId, userTypeId, token) => {
  return api.delete(`${url}/${userId}/${userTypeId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getPortals = async (loggedInUserId, token) => {
  return api.get(`${url}/getuserportals`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getSolarElectricianDropdown = async (data, token) => {
  return api.post(`${url}/getcombinedsolarelectriciancompliancedropdown`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.updateSolarElectrician = async (data, token) => {
  return api.post(`${url}/updatesolarelectriciancompliancedetails`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getSolarCompanyDropdown = async (data, token) => {
  return api.post(`${url}/getcombinedsolarcompanycompliancedropdown`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.updateCompanyElectrician = async (data, token) => {
  return api.post(`${url}/updatesolarcompanycompliancedetails`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.uploadDocuments = async (body, token) => {
  return api.post(`${url}/document/upload`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.saveExpiryDate = async (body, token) => {
  return api.post(`${url}/document/expirydate/save`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getResellerDropdown = async (body, token) => {
  return api.get(`${url}/reseller/${body?.userId}/${body?.userTypeId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getCoDropdown = async (token) => {
  return api.get(`${url}/co`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getAccountMangerDropdown = async (body, token) => {
  return api.get(`${url}/am/${body?.userId}/${body?.userTypeId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getSolarCompaniesAgainstReseller = async (body, token) => {
  return api.post(`${url}/resellers/resellerId/solarcompany`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};
UserService.updateUserDocumentStatusById = async (body, token) => {
  return api.post(`${url}/update-document-status`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.updateProfileImage = async (body, token) => {
  return api.post(`${url}/update-profile-picture`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getAsoAccreditationList = async (body, token) => {
  return api.post(`${url}/installers/saa`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.createUserTermsAndConditions = async (body, token) => {
  return api.post(`${url}/acceptTermsandCondition`, body, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getAssignedAccessTypes = async (userId, token) => {
  return api.get(`${url}/assigned-access-type/${userId}/get`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

UserService.getRequestedAccessTypesList = async (data, token) => {
  return api.post(`${url}/paginated-requested-access-types-list/get`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};
export default UserService;
