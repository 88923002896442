import { Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const ButtonSpinner = ({ loaderWithText, animation, variant, role, width, height }) => {
  return (
    <>
      {loaderWithText ? (
        <Row className='d-flex justify-content-center'>
          <Col className='d-flex justify-content-center align-items-center gap-1' lg={4}>
            {loaderWithText}
            <Spinner
              animation={animation}
              role={role}
              variant={variant}
              style={{ width: width, height: height }}
            />
          </Col>
        </Row>
      ) : (
        <Spinner
          animation={animation}
          role={role}
          variant={variant}
          style={{ width: width, height: height }}
        />
      )}
    </>
  );
};

export default ButtonSpinner;
