import { APP_ROUTES } from 'config/app-routes';
import { IoMdSettings } from 'react-icons/io';
import { MdManageAccounts } from 'react-icons/md';

import { ReactComponent as MyPortalIcon } from 'assets/svg/my-portal-icon.svg';
import { ReactComponent as UserIcon } from 'assets/svg/singleUserIcon.svg';

const menuItems = [
  // {
  //   label: "Profile",
  //   link: "/profile",
  //   icon: <ProfileIcon />,
  //   children: [],
  // },
  // {
  //   label: "Invite",
  //   icon: <UserIcon />,
  //   children: [
  //     { label: "Sent Invites", link: APP_ROUTES.private.SENT_INVITES },
  //     { label: "Send Invite", link: APP_ROUTES.private.INVITE_USER },
  //   ],
  // },
  {
    label: 'Users',
    icon: <UserIcon />,
    children: [
      { label: 'All Users', link: APP_ROUTES.private.USERS },
      { label: 'Sent Invites', link: APP_ROUTES.private.SENT_INVITES },
      { label: 'Send Invite', link: APP_ROUTES.private.INVITE_USER },
      { label: 'Requested Access Types', link: APP_ROUTES.private.REQUESTED_ACCESS_TYPES }
    ]
  },
  {
    label: 'My Portals',
    link: APP_ROUTES.private.MY_PORTALS,
    icon: <MyPortalIcon />,
    children: []
  },
  {
    label: 'Setting',
    icon: <IoMdSettings color='white' size='20px' />,
    children: [
      { label: 'Role', link: APP_ROUTES.private.USER_ROLE },
      // { label: 'Access Type', link: APP_ROUTES.private.USER_TYPE },
      { label: 'Auditor Group', link: APP_ROUTES.private.AUDITOR_GROUP },
      { label: 'Certificate', link: APP_ROUTES.private.COMMODITY_LISTING },
      // { label: "Menu", link: "/menu" },
      { label: 'Requirements', link: APP_ROUTES.private.REQUIREMENTS }
    ]
  },
  {
    label: 'Manage',
    icon: <MdManageAccounts color='white' size={'20px'} />,
    children: [
      { label: 'Approved PV Module', link: APP_ROUTES.private.PV_LIST },
      { label: 'Inverter List', link: APP_ROUTES.private.INVERTER_LIST },
      {
        label: 'SAA Accredited Installers List',
        link: APP_ROUTES.private.INSTALLER_LIST
      },
      { label: 'SWH List', link: APP_ROUTES.private.SWH_LIST },
      { label: 'Pricing Manager', link: APP_ROUTES.private.PRICING_MANAGER },
      { label: 'Battery Storage', link: APP_ROUTES.private.BATTERY_STORAGE }
      // { label: "Electricity Provider", link: "/electricity-provider" },
    ]
  }
  // {
  //   label: "Logout",
  //   link: "/",
  //   icon: <HiOutlineLogout color="white" size={"20px"} />,
  //   children: [],
  // },
];

export default menuItems;
