import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    activeAccount: null,
    isLoggedIn: false,
    previouslyLoggedIn:null,
    convertToId:null
  },
  reducers: {
    setActiveAccount: (state, action) => {
      state.activeAccount = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    setPreviouslyLoggedIn:(state,action)=>{
      state.previouslyLoggedIn=action.payload
    },
    setConvertToID:(state,action)=>{
      state.convertToId=action.payload

    }
  }
});

export const { setActiveAccount, setUser, clearUser,setPreviouslyLoggedIn,setConvertToID } = userSlice.actions;
export default userSlice.reducer;
