import { createSlice } from '@reduxjs/toolkit';

const submenuSlice = createSlice({
  name: 'submenu',
  initialState: [],
  reducers: {
    toggleSubmenu: (state, action) => {
      const submenuLabel = action.payload;
      return state.includes(submenuLabel)
        ? state.filter((item) => item !== submenuLabel)
        : [...state, submenuLabel];
    }
  }
});

export const { toggleSubmenu } = submenuSlice.actions;

export default submenuSlice.reducer;
