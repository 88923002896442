import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  installerAndDesigner: {
    pvd: {
      physicalAddress: {},
      poBoxAddress: {}
    },
    swh: {
      physicalAddress: {},
      poBoxAddress: {}
    }
  },
  saasInvoiceDetail: {}
};

export const editCompanyStaffSlice = createSlice({
  name: 'editCompanyStaff',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addPvdInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          ...action.payload
        }
      };
    },
    addPvdPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          physicalAddress: action.payload
        }
      };
    },
    addPvdPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          poBoxAddress: action.payload
        }
      };
    },

    addSwhInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          ...action.payload
        }
      };
    },
    addSwhPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          physicalAddress: action.payload
        }
      };
    },
    addSwhPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          poBoxAddress: action.payload
        }
      };
    },
    resetPvd: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    resetSwh: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    addInvoiceDetail: (state, action) => {
      state.saasInvoiceDetail = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addPvdInstallerDetail,
  addPvdPhysicalAddress,
  addPvdPoBoxAddress,
  addSwhInstallerDetail,
  addSwhPhysicalAddress,
  addSwhPoBoxAddress,
  addInvoiceDetail,
  resetPvd,
  resetSwh
} = editCompanyStaffSlice.actions;

export default editCompanyStaffSlice.reducer;
