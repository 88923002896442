import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {
    driverLicenseDocumentsFront: [],
    driverLicenseDocumentsBack: [],
    asoLicenseDocumentFront: [],
    asoLicenseDocumentBack: [],
    electricalLicenseDocumentFront: [],
    electricalLicenseDocumentBack: [],
    plumberLicenseDocumentFront: [],
    plumberLicenseDocumentBack: [],

    accreditationDocuments: [],
    insuranceDocuments: [],
    proofOfBusinessDocuments: [],
    signatureDocuments: [],
    selfieDocuments: [],
    proofOfIdentityDocuments: []
  },
  wholesalerDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  saasInvoiceDetail: {}
};

export const createUserSlice = createSlice({
  name: 'createUser',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        ...action.payload
      };
    },
    addDriverLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        driverLicenseDocumentsFront: action.payload
      };
    },
    addDriverLicenseBackDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        driverLicenseDocumentsBack: action.payload
      };
    },
    addASOLicenseFrontDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        asoLicenseDocumentFront: action.payload
      };
    },
    addASOLicenseBackDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        asoLicenseDocumentBack: action.payload
      };
    },
    addPlumberLicenseFrontDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        plumberLicenseDocumentFront: action.payload
      };
    },
    addPlumberLicenseBackDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        plumberLicenseDocumentBack: action.payload
      };
    },
    addElectricalLicenseFrontDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        electricalLicenseDocumentFront: action.payload
      };
    },
    addElectricalLicenseBackDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        electricalLicenseDocumentBack: action.payload
      };
    },
    addSelfieDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        selfieDocuments: action.payload
      };
    },
    addProofOfIdentityDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfIdentityDocuments: action.payload
      };
    },
    addAccreditationDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        accreditationDocuments: action.payload
      };
    },
    addInsuranceDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        insuranceDocuments: action.payload
      };
    },
    addProofOfBusinessDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfBusinessDocuments: action.payload
      };
    },
    addSignatureDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        signatureDocuments: action.payload
      };
    },
    addWholeSalerDetail: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        ...action.payload
      };
    },
    addWholeSalerPhysicalAddress: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        physicalAddress: action.payload
      };
    },
    addWholeSalerPoBoxAddress: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        poBoxAddress: action.payload
      };
    },
    addSaasInvoiceDetail: (state, action) => {
      return {
        ...state,
        saasInvoiceDetail: action.payload
      };
    },
    resetCreateUser: (state) => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addWholeSalerDetail,
  addWholeSalerPhysicalAddress,
  addWholeSalerPoBoxAddress,
  addDriverLicenseDocuments,
  addDriverLicenseBackDocuments,
  addASOLicenseFrontDocuments,
  addASOLicenseBackDocuments,
  addPlumberLicenseFrontDocuments,
  addPlumberLicenseBackDocuments,
  addElectricalLicenseFrontDocuments,
  addElectricalLicenseBackDocuments,
  addSelfieDocuments,
  addProofOfIdentityDocuments,
  addAccreditationDocuments,
  addInsuranceDocuments,
  addProofOfBusinessDocuments,
  addSignatureDocuments,
  addSaasInvoiceDetail,
  resetCreateUser
} = createUserSlice.actions;

export default createUserSlice.reducer;
