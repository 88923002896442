import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedItems: [],
  selectedAccountManagerItems: [],
  selectedComplianceOfficerItems: [],
  selectedResellerItems: []
};

const selectedItemsSlice = createSlice({
  name: 'selectedItems',
  initialState,
  reducers: {
    SelectedItems: (state, action) => {
      return {
        ...state,
        selectedItems: action.payload
      };
    },
    SelectedAccountManagerItems: (state, action) => {
      return {
        ...state,
        selectedAccountManagerItems: action.payload
      };
    },
    SelectedComplianceOfficerItems: (state, action) => {
      return {
        ...state,
        selectedComplianceOfficerItems: action.payload
      };
    },
    SelectedResellerItems: (state, action) => {
      return {
        ...state,
        selectedResellerItems: action.payload
      };
    },
    resetSelection: (state) => {
      return {
        ...initialState
      };
    }
  }
});

export const {
  SelectedItems,
  resetSelection,
  SelectedAccountManagerItems,
  SelectedComplianceOfficerItems,
  SelectedResellerItems
} = selectedItemsSlice.actions;
export default selectedItemsSlice.reducer;
