import { APP_ROUTES } from 'config/app-routes';
import Layout from 'layout/applayout';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import AccessDenied from 'pages/access-denied';
import NotFound from 'pages/not-found';
import PrivacyPolicyPage from 'pages/privacy-policy';
import TermsAndConditionsPage from 'pages/terms-and-conditions';
import TradingTermsAndConditionsPage from 'pages/trading-terms-and-conditions';

import PageLoader from 'components/shared-components/page-loader';

const CommodityProfilePage = lazy(() => import('pages/commodity-profile-page'));
const MainLandingPage = lazy(() => import('pages/welcome-landing-page'));

const PendingUserRoutes = () => {
  const loggedInUser = useSelector((state) => state.user.user);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route
          path={APP_ROUTES.private.HOME}
          exact
          element={
            <Navigate
              replace
              to={APP_ROUTES.private.PROFILE(loggedInUser?.userTypeID, loggedInUser?.id)}
            />
          }
        />
        <Route path={APP_ROUTES.private.AUTHENTICATION_CALLBACK} element={<MainLandingPage />} />

        <Route
          path={APP_ROUTES.private.PROFILE(':userType', ':id')}
          element={
            <Layout showSidebar={false}>
              <CommodityProfilePage />
            </Layout>
          }
        />
        <Route path={APP_ROUTES.private.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

        <Route
          path={APP_ROUTES.private.TERMS_AND_CONDITIONS}
          element={<TermsAndConditionsPage />}
        />

        <Route
          path={APP_ROUTES.private.TRADING_TERMS_AND_CONDITIONS}
          element={<TradingTermsAndConditionsPage />}
        />

        <Route path={APP_ROUTES.private.ACCESS_DENIED} element={<AccessDenied />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default PendingUserRoutes;
