import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {
    signatureDocuments: [],
    installerSelfieDocuments: [],
    driverLicenseDocuments: [],
    asoLicenseDocuments: [],
    electricalLicenseDocuments: [],
    plumberLicenseDocuments: [],
    proofOfBusinessAddressDocuments: [],
    publicLiabilityInsuranceDocuments: [],

    driverLicenseIssueDate: null,
    driverLicenseExpiryDate: null,
    asoLicenseIssueDate: null,
    asoLicenseExpiryDate: null,
    electricianLicenseIssueDate: null,
    electricianLicenseExpiryDate: null,
    plumberLicenseIssueDate: null,
    plumberLicenseExpiryDate: null,
    publicLiabilityInsuranceIssueDate: null,
    publicLiabilityInsuranceExpiryDate: null
  },
  wholesalerDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  installerAndDesigner: {
    pvd: {
      physicalAddress: {},
      poBoxAddress: {}
    },
    swh: {
      physicalAddress: {},
      poBoxAddress: {}
    }
  },
  saasInvoiceDetail: {}
};

export const editUserSlice = createSlice({
  name: 'editUser',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = {
        ...state.personalDetail,
        ...action.payload
      };
    },
    addPersonalDetailWholesalerAndSaasUser: (state, action) => {
      state.personalDetail = {
        ...state.personalDetail,
        ...action.payload
      };
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        ...action.payload
      };
    },
    addWholeSalerDetail: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        ...action.payload
      };
    },
    addWholeSalerPhysicalAddress: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        physicalAddress: action.payload
      };
    },
    addWholeSalerPoBoxAddress: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        poBoxAddress: action.payload
      };
    },
    addDriverLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        driverLicenseDocuments: action.payload.documents,
        driverLicenseIssueDate: action.payload.issueDate,
        driverLicenseExpiryDate: action.payload.expiryDate
      };
    },
    addASOLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        asoLicenseDocuments: action.payload.documents,
        asoLicenseIssueDate: action.payload.issueDate,
        asoLicenseExpiryDate: action.payload.expiryDate
      };
    },
    addPlumberLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        plumberLicenseDocuments: action.payload.documents,
        plumberLicenseIssueDate: action.payload.issueDate,
        plumberLicenseExpiryDate: action.payload.expiryDate
      };
    },
    addElectricalLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        electricalLicenseDocuments: action.payload.documents,
        electricianLicenseIssueDate: action.payload.issueDate,
        electricianLicenseExpiryDate: action.payload.expiryDate
      };
    },
    addSelfieDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        installerSelfieDocuments: action.payload
      };
    },
    addProofOfIdentityDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfIdentityDocuments: action.payload
      };
    },
    addInsuranceDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        publicLiabilityInsuranceDocuments: action.payload.documents,
        publicLiabilityInsuranceIssueDate: action.payload.issueDate,
        publicLiabilityInsuranceExpiryDate: action.payload.expiryDate
      };
    },
    addProofOfBusinessDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfBusinessAddressDocuments: action.payload
      };
    },
    addSignatureDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        signatureDocuments: action.payload
      };
    },

    addPvdInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          ...action.payload
        }
      };
    },
    addPvdPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          physicalAddress: action.payload
        }
      };
    },
    addPvdPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          poBoxAddress: action.payload
        }
      };
    },

    addSwhInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          ...action.payload
        }
      };
    },
    addSwhPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          physicalAddress: action.payload
        }
      };
    },
    addSwhPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          poBoxAddress: action.payload
        }
      };
    },
    resetPvd: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    resetSwh: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    addSaasInvoiceDetail: (state, action) => {
      return {
        ...state,
        saasInvoiceDetail: action.payload
      };
    },
    resetEditUser: (state) => {
      return initialState;
    }
  }
});

export const {
  addPersonalDetail,
  addPersonalDetailWholesalerAndSaasUser,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addWholeSalerDetail,
  addWholeSalerPhysicalAddress,
  addWholeSalerPoBoxAddress,
  addDriverLicenseDocuments,
  addASOLicenseDocuments,
  addPlumberLicenseDocuments,
  addElectricalLicenseDocuments,
  addSelfieDocuments,
  addProofOfIdentityDocuments,
  addInsuranceDocuments,
  addProofOfBusinessDocuments,
  addSignatureDocuments,
  addPvdInstallerDetail,
  addPvdPhysicalAddress,
  addPvdPoBoxAddress,
  addSwhInstallerDetail,
  addSwhPhysicalAddress,
  addSwhPoBoxAddress,
  resetPvd,
  resetSwh,
  addSaasInvoiceDetail,
  resetEditUser
} = editUserSlice.actions;

export default editUserSlice.reducer;
