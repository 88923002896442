import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ButtonSpinner from 'components/shared-components/button-loader/Spinner';

import styles from '../footer.module.css';

const Footer = ({
  text = 'Save',
  text2 = 'Cancel',
  handleClose,
  handleSubmit,
  cancel = true,
  loading,
  checklistId,
  disabled = false
}) => {
  return (
    <Row className='d-flex justify-content-end'>
      <Col className='d-flex justify-content-end '>
        <Button
          themeColor={'primary'}
          type='submit'
          onClick={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
          className={styles.buttonPadding}
          disabled={disabled}
        >
          {loading ? (
            <ButtonSpinner animation={'border'} variant={'light'} height={'25px'} width={'25px'} />
          ) : checklistId === 0 ? (
            'Create'
          ) : (
            text
          )}
        </Button>
        {cancel && (
          <Button
            onClick={(event) => {
              event.preventDefault();
              handleClose();
            }}
            className={styles.buttonPadding}
          >
            {text2}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default Footer;
