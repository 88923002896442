import { env } from 'config';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import LoaderModal from 'components/shared-components/modal-loader/modal-loader';

import useLogout from 'hooks/use-logout';

import { UserStatus } from 'constants/index';

import Toaster from 'utils/toastify';

import PendingUserRoutes from './pending-user-routes';
import ProtectedRoutes from './protected-routes';
import UnprotectedRoutes from './unprotected-routes';

const AllRoutes = () => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user.user);

  const { loading, logout } = useLogout();

  useEffect(() => {
    if (env.REACT_APP_ENV === 'production') {
      let devtools = false;
      const detectDevTools = () => {
        const widthThreshold = 160;
        const heightThreshold = 160;

        if (
          (window.outerWidth - window.innerWidth > widthThreshold ||
            window.outerHeight - window.innerHeight > heightThreshold) &&
          !devtools
        ) {
          devtools = true;

          logout();
          alert('DevTools detected - Logging out');
        }
      };

      const interval = setInterval(detectDevTools, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <>
      <LoaderModal loading={loading} />
      <Toaster />
      {token && user ? (
        <>{user?.statusId === UserStatus.Approved ? <ProtectedRoutes /> : <PendingUserRoutes />}</>
      ) : (
        <UnprotectedRoutes />
      )}
    </>
  );
};

export default AllRoutes;
