import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  saasInvoiceDetail: {}
};

export const accountManager = createSlice({
  name: 'accountManager',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      return {
        ...state,
        personalDetail: action.payload
      };
    },
    addInvoiceDetail: (state, action) => {
      return {
        ...state,
        saasInvoiceDetail: action.payload
      };
    },
    resetAccountManager: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { addPersonalDetail, addInvoiceDetail, resetAccountManager } = accountManager.actions;

export default accountManager.reducer;
