import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {
    signatureDocuments: [],
    selfieDocuments: [],
    driverLicenseDocuments: [],
    proofOfIdentityDocuments: []
  },
  installerAndDesigner: {
    pvd: {
      physicalAddress: {},
      poBoxAddress: {}
    },
    swh: {
      physicalAddress: {},
      poBoxAddress: {}
    }
  },
  connectedDevices: {},
  saasInvoiceDetail: {},
  complianceStatus: {}
};

export const editSolarElectricianSlice = createSlice({
  name: 'editSolarElectrician',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = action.payload;
    },
    addDriverLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        driverLicenseDocuments: action.payload
      };
    },
    addSelfieDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        selfieDocuments: action.payload
      };
    },
    addProofOfIdentityDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfIdentityDocuments: action.payload
      };
    },
    addSignatureDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        signatureDocuments: action.payload
      };
    },
    addPvdInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          ...action.payload
        }
      };
    },
    addPvdPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          physicalAddress: action.payload
        }
      };
    },
    addPvdPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          poBoxAddress: action.payload
        }
      };
    },

    addSwhInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          ...action.payload
        }
      };
    },
    addSwhPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          physicalAddress: action.payload
        }
      };
    },
    addSwhPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          poBoxAddress: action.payload
        }
      };
    },
    resetPvd: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    resetSwh: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    addInvoiceDetail: (state, action) => {
      state.saasInvoiceDetail = action.payload;
    },
    addConnectedDevices: (state, action) => {
      state.connectedDevices = action.payload;
    },
    addComplianceStatus: (state, action) => {
      state.complianceStatus = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addDriverLicenseDocuments,
  addSelfieDocuments,
  addProofOfIdentityDocuments,
  addSignatureDocuments,
  addPvdInstallerDetail,
  addPvdPhysicalAddress,
  addPvdPoBoxAddress,
  addSwhInstallerDetail,
  addSwhPhysicalAddress,
  addSwhPoBoxAddress,
  addInvoiceDetail,
  resetPvd,
  resetSwh,
  addConnectedDevices,
  addComplianceStatus
} = editSolarElectricianSlice.actions;

export default editSolarElectricianSlice.reducer;
