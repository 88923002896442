import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {}
};

export const superAdmin = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    resetState: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { addPersonalDetail, resetState } = superAdmin.actions;

export default superAdmin.reducer;
