import { useEffect } from 'react';

import SpinnerLoader from 'components/shared-components/spinner-loader';

import useLogout from 'hooks/use-logout';

const Logout = () => {
  const { logout } = useLogout();

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
      <SpinnerLoader />
      logging out.....
    </div>
  );
};

export default Logout;
