import { createSlice } from '@reduxjs/toolkit';

const userDocumentStatusSlice = createSlice({
  name: 'userDocumentStatus',
  initialState: {
    status: false
  },
  reducers: {
    setDocumentStatus: (state, action) => {
      state.status = action.payload;
    }
  }
});

export const { setDocumentStatus } = userDocumentStatusSlice.actions;

export default userDocumentStatusSlice.reducer;
