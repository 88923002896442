import { useEffect, useState } from 'react';

import LoaderModal from 'components/shared-components/modal-loader/modal-loader';

import usePrivacy from 'hooks/use-privacy';

import styles from './privacy-policy.module.css';

const PrivacyPolicy = () => {
  const { loading, getPrivacyPolicy } = usePrivacy();
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    getPrivacyPolicy((resp) => {
      setPolicy(resp?.data?.name);
    });
  }, []);

  return (
    <>
      <LoaderModal loading={loading} />
      {policy && (
        <div className={styles.privacyContainer} dangerouslySetInnerHTML={{ __html: policy }}></div>
      )}
    </>
  );
};

export default PrivacyPolicy;
