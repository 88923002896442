import { Input, InputSuffix, TextBox } from '@progress/kendo-react-inputs';

import styles from './text-input.module.css';

const InputField = ({
  label,
  required,
  value,
  handleBlur,
  handleChange,
  handleShowPassword = () => {},
  errors,
  touched,
  name,
  type = 'text',
  disabled = false,
  variant = '',
  placeholder = '',
  info = false,
  showIcon = false,
  icon = ''
}) => {
  const labelVariant = {
    primary: 'labelPrimary'
  };

  const inputVariant = {
    primary: 'inputPrimary'
  };

  const feedbackVariant = {
    primary: 'primaryInputFeedback'
  };

  return (
    <>
      {label && (
        <h6 className={variant ? styles[labelVariant[variant]] : styles.label}>
          {label}:{required && <p style={{ marginBottom: '5px' }}>*</p>}
        </h6>
      )}
      {!showIcon ? (
        <Input
          type={type}
          name={name}
          className={`${errors && touched ? styles.error : ''} ${
            variant && styles[inputVariant[variant]]
          }`}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <TextBox
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          className={`d-flex just-fy-content-center align-items-center ${
            errors && touched ? styles.error : ''
          } ${variant && styles[inputVariant[variant]]}`}
          required={true}
          onBlur={handleBlur}
          name={name}
          type={type}
          suffix={() => (
            <>
              <InputSuffix>
                {showIcon ? <span onClick={() => handleShowPassword(name)}>{icon}</span> : ''}
              </InputSuffix>
            </>
          )}
        />
      )}

      {info && <p className={`${styles.info} my-2`}>{info}</p>}
      {errors && touched && (
        <div className={variant ? styles[feedbackVariant[variant]] : styles.inputFeedback}>
          {errors}
        </div>
      )}
    </>
  );
};

export default InputField;
