import { createSlice } from '@reduxjs/toolkit';

const sequenceSlice = createSlice({
  name: 'sequence',
  initialState: {
    sequenceNumber: [1, 2, 3]
  },
  reducers: {
    updateOrder: (state, action) => {
      state.sequenceNumber = action.payload;
    }
  }
});

export const { updateOrder } = sequenceSlice.actions;
export default sequenceSlice.reducer;
