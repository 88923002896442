import { APP_ROUTES } from 'config/app-routes';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io';
import { Menu, MenuItem, Sidebar, sidebarClasses, SubMenu } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LogoWhite, FpLogoWithText } from 'assets/svg';

import { toggleSubmenu } from 'redux/subMenu/submenuSlice';

import useCheckAccess from 'hooks/use-check-access';

import { userRole, userType } from 'constants/index';

import menuItems from 'utils/navbarItems/navbarItems';
import { getWindowSize } from 'utils/utils';

import './Prosidebar.css';
import styles from './sidebar.module.css';

function ProSidebar() {
  const loggedInUser = useSelector((state) => state.user.user);
  const userAccess = useCheckAccess(userRole.User, false);
  const inviteAccess = useCheckAccess(userRole.Invite, false);
  const portalsAccess = useCheckAccess(userRole.Apps, false);
  const roleAccess = useCheckAccess(userRole.Role, false);
  const userTypeAccess = useCheckAccess(userRole.UserType, false);
  const coAccess = useCheckAccess(userRole.AuditorGroup, false);
  const commodityAccess = useCheckAccess(userRole.Commodity, false);
  const checklistAccess = useCheckAccess(userRole.Requirement, false);
  const pvModuleAccess = useCheckAccess(userRole.ApprovedPVModule, false);
  const inverterAccess = useCheckAccess(userRole.InverterList, false);
  const asoInstallerAccess = useCheckAccess(userRole.SAAAccreditedInstallersList, false);
  const swhListAccess = useCheckAccess(userRole.SWHList, false);
  const pricingManagerAccess = useCheckAccess(userRole.PricingManager, false);
  const batterStorageListAccess = useCheckAccess(userRole.BatteryStorageList, false);
  const electricityProviderAccess = useCheckAccess(userRole.ElectricityProvider, false);

  const { id } = useSelector((state) => state.user.user);
  const [sideBarItems, setSideBarItems] = useState([]);

  const filterMenuItems = () => {
    let newItems = [...menuItems];

    if (!userAccess.read && !inviteAccess.read) {
      newItems = newItems.filter((item) => item?.label !== 'Users');
    }

    if (loggedInUser?.userTypeID !== userType.SuperAdmin) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Users') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Requested Access Types')
          };
        }
        return item;
      });
    }

    if (
      !roleAccess?.read &&
      !userTypeAccess?.read &&
      !coAccess?.read &&
      !commodityAccess?.read &&
      !checklistAccess?.read
    ) {
      newItems = newItems.filter((item) => item?.label !== 'Setting');
    }

    if (
      !pvModuleAccess?.read &&
      !inverterAccess?.read &&
      !asoInstallerAccess?.read &&
      !swhListAccess?.read &&
      !pricingManagerAccess?.read &&
      !batterStorageListAccess?.read &&
      !electricityProviderAccess?.read
    ) {
      newItems = newItems.filter((item) => item?.label !== 'Manage');
    }

    if (!portalsAccess.read) {
      newItems = newItems.filter((item) => item?.label !== 'My Portals');
    }

    if (!roleAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Setting') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Role')
          };
        }
        return item;
      });
    }

    if (!userTypeAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Setting') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Access Type')
          };
        }
        return item;
      });
    }

    if (!coAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Setting') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Auditor Group')
          };
        }
        return item;
      });
    }

    if (!commodityAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Setting') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Certificate')
          };
        }
        return item;
      });
    }

    if (!checklistAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Setting') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Requirements')
          };
        }
        return item;
      });
    }

    if (!pvModuleAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Approved PV Module')
          };
        }
        return item;
      });
    }

    if (!inverterAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Inverter List')
          };
        }
        return item;
      });
    }

    if (!asoInstallerAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter(
              (child) => child?.label !== 'SAA Accredited Installers List'
            )
          };
        }
        return item;
      });
    }

    if (!swhListAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'SWH List')
          };
        }
        return item;
      });
    }

    if (!pricingManagerAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Pricing Manager')
          };
        }
        return item;
      });
    }

    if (!batterStorageListAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Battery Storage')
          };
        }
        return item;
      });
    }

    if (!electricityProviderAccess?.read) {
      newItems = newItems?.map((item) => {
        if (item?.label === 'Manage') {
          return {
            ...item,
            children: item.children.filter((child) => child?.label !== 'Electricity Provider')
          };
        }
        return item;
      });
    }

    newItems = newItems.map((item) => {
      if (item?.label === 'Users') {
        return {
          ...item,
          children: item.children.filter((child) => {
            if (child.link === APP_ROUTES.private.USERS && !userAccess.read) return false;
            if (child.link === APP_ROUTES.private.SENT_INVITES && !inviteAccess.read) return false;

            if (child.link === APP_ROUTES.private.INVITE_USER && !inviteAccess.read) return false;

            return true;
          })
        };
      }
      return item;
    });

    setSideBarItems(newItems);
  };

  useEffect(() => {
    filterMenuItems();
    // eslint-disable-next-line
  }, [
    id,
    userAccess,
    inviteAccess,
    portalsAccess,
    roleAccess,
    userTypeAccess,
    coAccess,
    commodityAccess,
    checklistAccess,
    pvModuleAccess,
    inverterAccess,
    asoInstallerAccess,
    swhListAccess,
    pricingManagerAccess,
    batterStorageListAccess,
    electricityProviderAccess
  ]);

  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const openSubmenus = useSelector((state) => state.submenu);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleSubmenuToggle = (label) => {
    dispatch(toggleSubmenu(label));
  };

  useEffect(() => {
    const handleResize = () => {
      if (getWindowSize().innerWidth <= 1024) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      handleToggleSidebar={handleToggleSidebar}
      handleCollapsedChange={handleCollapsedChange}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: user?.themeColor ?? '#1e2023'
        }
      }}
    >
      <main>
        {collapsed ? (
          <div className={styles.logoSectionSmall}>
            <div className={styles.iconSmallSection}>
              <IoIosArrowDropright
                className={styles.iconSmall}
                onClick={() => {
                  setCollapsed(false);
                }}
              />
            </div>
            <Link to={APP_ROUTES.private.MY_PORTALS}>
              <FpLogoWithText width={79} height={60} />
            </Link>
          </div>
        ) : (
          <div className={styles.logoSectionTop}>
            <div className={styles.logoSection}>
              <IoIosArrowDropleft
                className={styles.icon}
                onClick={() => {
                  setCollapsed(true);
                }}
              />
              <Link to={APP_ROUTES.private.MY_PORTALS}>
                <LogoWhite width={126} height={78} />
              </Link>
            </div>
          </div>
        )}

        <Menu closeOnClick>
          {sideBarItems.map((item) => (
            <React.Fragment key={item?.label}>
              {item?.children.length > 0 ? (
                <SubMenu
                  key={`submenu-${item?.label}`}
                  label={item?.label}
                  icon={item?.icon}
                  open={openSubmenus.includes(item?.label)}
                  onClick={() => handleSubmenuToggle(item?.label)}
                  style={{
                    backgroundColor: user?.themeColor ?? '#1e2023'
                  }}
                >
                  {item.children.map((subItem) => (
                    <MenuItem
                      key={`subItem-${subItem?.label}`}
                      component={<Link key={subItem?.label} to={subItem?.link} />}
                      style={{
                        backgroundColor: user?.themeColor ?? '#1e2023'
                      }}
                      rootStyles={{
                        backgroundColor: user?.themeColor ?? '#1e2023'
                      }}
                    >
                      {subItem?.label}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <>
                  {item?.label === 'Profile' ? (
                    <MenuItem
                      key={`menu-item-${item?.label}`}
                      icon={item?.icon}
                      style={{
                        backgroundColor: user?.themeColor ?? '#1e2023'
                      }}
                      rootStyles={{
                        backgroundColor: user?.themeColor ?? '#1e2023'
                      }}
                      component={
                        <Link
                          key={`link-${item?.label}`}
                          to={`${item?.link}/${user?.userTypeID}/${user?.id}`}
                        />
                      }
                    >
                      {item?.label}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={`menu-item-${item?.label}`}
                      icon={item?.icon}
                      style={{
                        backgroundColor: user?.themeColor ?? '#1e2023'
                      }}
                      rootStyles={{
                        backgroundColor: user?.themeColor ?? '#1e2023'
                      }}
                      component={<Link key={`link-${item?.label}`} to={item?.link} />}
                    >
                      {item?.label}
                    </MenuItem>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </Menu>
      </main>
    </Sidebar>
  );
}
export default ProSidebar;
