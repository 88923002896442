import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {
    driverLicenseDocuments: [],
    accreditationDocuments: [],
    insuranceDocuments: [],
    proofOfBusinessDocuments: [],
    signatureDocuments: []
  },
  saasInvoiceDetail: {}
};

export const solarCompany = createSlice({
  name: 'solarCompany',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        ...action.payload
      };
    },
    addDriverLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        driverLicenseDocuments: action.payload
      };
    },
    addAccreditationDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        accreditationDocuments: action.payload
      };
    },
    addInsuranceDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        insuranceDocuments: action.payload
      };
    },
    addProofOfBusinessDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfBusinessDocuments: action.payload
      };
    },
    addSignatureDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        signatureDocuments: action.payload
      };
    },
    addInvoiceDetail: (state, action) => {
      state.saasInvoiceDetail = action.payload;
    },
    resetCreateSolarCompany: (state) => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addInvoiceDetail,
  addDriverLicenseDocuments,
  addAccreditationDocuments,
  addInsuranceDocuments,
  addProofOfBusinessDocuments,
  addSignatureDocuments,
  resetCreateSolarCompany
} = solarCompany.actions;

export default solarCompany.reducer;
