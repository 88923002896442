import { useState } from 'react';
// Replace with actual import path
import { useSelector } from 'react-redux';

import UserService from 'services/user';

import showToast from 'utils/notificationToast/notificationToast';

const useUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user.user);

  const getUserById = async (id, userType, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.getUserById(id, userType, token);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to fetch user detail');
        showToast('Unable to fetch user detail ', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to fetch user detail');
      showToast('Unable to fetch user detail', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getUsersList = async (body, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.getUserList(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to fetch user list');
        showToast('Unable to fetch user list ', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to fetch user list');
      showToast('Unable to fetch user list', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getUserInvitesList = async (body, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.getUserInvitesList(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to fetch invite list');
        showToast('Unable to fetch invite list ', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to fetch invite list');
      showToast('Unable to fetch invite list', 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteUserById = async (userId, userTypeId, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.deleteUserById(userId, userTypeId, token);
      if (resp.isSucceeded) {
        handleSuccess(resp);
        showToast('User deleted successfully', 'success');
      } else {
        setError(resp.message || 'Unable to delete user');
        showToast(resp.message || 'Unable to delete user ', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to delete user');
      showToast('Unable to delete user', 'error');
    } finally {
      setLoading(false);
    }
  };

  const uploadDocuments = async (body, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.uploadDocuments(
        {
          ...body,
          performedById: user?.id
        },
        token
      );
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
        showToast('Uploaded successfully', 'success');
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast('Unable to upload ', 'error');
        }
        setError(resp.message || 'Unable to upload');
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast('Unable to upload ', 'error');
      }
      setError(error.message || 'Unable to upload');
    } finally {
      setLoading(false);
    }
  };
  const saveExpiryDate = async (body, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.saveExpiryDate(
        {
          ...body,
          performedById: user?.id
        },
        token
      );
      if (resp.isSucceeded) {
        handleSuccess(resp);
        showToast('Expiry Date saved successfully', 'success');
      } else {
        setError(resp.message || 'Unable to save expiry date');
        showToast('Unable to save expiry date', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to save expiry date');
      showToast('Unable to save expiry date', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getResellerDropdown = async (body, handleSuccess = {}) => {
    setLoading(true);
    try {
      const resp = await UserService.getResellerDropdown(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to fetch reseller dropdown', 'error');
      }
    } catch (error) {
      showToast('Unable to fetch reseller dropdown', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getCoDropdown = async (handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.getCoDropdown(token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to fetch auditor dropdown', 'error');
      }
    } catch (error) {
      showToast('Unable to fetch auditor dropdown', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAccountMangerDropdown = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.getAccountMangerDropdown(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to fetch Customer success manager dropdown', 'error');
      }
    } catch (error) {
      showToast('Unable to fetch Customer success manager dropdown', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getSolarCompaniesAgainstReseller = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.getSolarCompaniesAgainstReseller(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to fetch Solar Retailers list against reseller', 'error');
      }
    } catch (error) {
      showToast('Unable to fetch Solar Retailers list against reseller', 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateUserDocumentStatusById = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.updateUserDocumentStatusById(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
        showToast('User document status updated successfully', 'success');
      } else {
        showToast('Unable to update document status', 'error');
      }
    } catch (error) {
      showToast('Unable to update document status', 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateProfileImage = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.updateProfileImage(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
        showToast('Profile Image updated successfully', 'success');
      } else {
        showToast('Unable to update profile image', 'error');
      }
    } catch (error) {
      showToast('Unable to update profile image', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAsoAccreditationList = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.getAsoAccreditationList(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to get installers list', 'error');
      }
    } catch (error) {
      showToast('Unable to get installers list', 'error');
    } finally {
      setLoading(false);
    }
  };

  const createUserTermsAndConditions = async (handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.createUserTermsAndConditions({}, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to update user terms and privacy policy', 'error');
      }
    } catch (error) {
      showToast('Unable to update user terms and privacy policy', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAssignedAccessTypes = async (userId, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await UserService.getAssignedAccessTypes(userId, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to get assigned access types', 'error');
      }
    } catch (error) {
      showToast('Unable to get assigned access types', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getRequestedAccessTypesList = async (body, handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await UserService.getRequestedAccessTypesList(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to fetch requested access types list');
        showToast('Unable to fetch requested access types list ', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to fetch requested access types list');
      showToast('Unable to fetch requested access types list', 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    getUsersList,
    getUserInvitesList,
    getUserById,
    deleteUserById,
    uploadDocuments,
    saveExpiryDate,
    getResellerDropdown,
    getCoDropdown,
    getAccountMangerDropdown,
    getSolarCompaniesAgainstReseller,
    updateUserDocumentStatusById,
    updateProfileImage,
    getAsoAccreditationList,
    createUserTermsAndConditions,
    getAssignedAccessTypes,
    getRequestedAccessTypesList
  };
};

export default useUser;
