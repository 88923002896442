import React from 'react';

import AnimatedLoader from '../animated-loader';

const PageLoader = () => {
  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
      <AnimatedLoader />
    </div>
  );
};

export default PageLoader;
