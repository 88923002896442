import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  saasInvoiceDetail: {}
};

export const companyStaff = createSlice({
  name: 'companyStaff',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      return {
        ...state,
        personalDetail: action.payload
      };
    },
    addInvoiceDetail: (state, action) => {
      return {
        ...state,
        saasInvoiceDetail: action.payload
      };
    },
    resetCompanyStaff: () => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { addPersonalDetail, addInvoiceDetail, resetCompanyStaff } = companyStaff.actions;

export default companyStaff.reducer;
