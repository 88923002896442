import { createSlice } from '@reduxjs/toolkit';

export const checklistSlice = createSlice({
  name: 'checklist',
  initialState: {
    templateData: {
      id: 0,
      name: ''
    },
    createTemplateData: {
      id: 0,
      name: ''
    },
    mappedData: {},
    dropdown: false,
    dialog: false,
    selectedId: null,
    status: false,
    tempIdFromAdd: null,
    checklistIdFromAdd: null
  },
  reducers: {
    setMappedData: (state, action) => {
      state.mappedData = action.payload;
    },
    resetMappedData: (state) => {
      state.mappedData = {
        template: state.mappedData.template
      };
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setStatus: (state) => {
      state.status = !state.status;
    },
    setRefreshDropdown: (state) => {
      state.dropdown = !state.dropdown;
    },
    setOpenDialog: (state) => {
      state.dialog = !state.dialog;
    },
    setSaveasNewTemplateData(state, action) {
      state.templateData.id = action.payload.newTemplateId;
      state.templateData.name = action.payload.name;
    },
    setTempIdFromAdd: (state, action) => {
      state.tempIdFromAdd = action.payload;
    },
    setChecklistIdFromAdd: (state, action) => {
      state.checklistIdFromAdd = action.payload;
    },
    setSaveasNewTempId: (state, action) => {
      state.newTempId = action.payload;
    },
    setCreateTemplateData(state, action) {
      state.createTemplateData.id = action.payload.id;
      state.createTemplateData.name = action.payload.name;
    }
  }
});

export const {
  setMappedData,
  resetMappedData,
  setStatus,
  setSelectedId,
  setRefreshDropdown,
  setOpenDialog,
  setSaveasNewTemplateData,
  setCreateTemplateData,
  setTempIdFromAdd,
  setChecklistIdFromAdd
} = checklistSlice.actions;

export const selectTempIdFromAdd = (state) => state.checklist.tempIdFromAdd;
export const selectChecklistIdFromAdd = (state) => state.checklist.checklistIdFromAdd;
export const refreshDropdown = (state) => state.checklist.dropdown;
export const openDialog = (state) => state.checklist.dialog;
export const saveAsNewTempData = (state) => state.checklist.templateData;
export const createTempData = (state) => state.checklist.createTemplateData;
export const selectMappedData = (state) => state.checklist.mappedData;
export const selectSelectedId = (state) => state.checklist.selectedId;
export const status = (state) => state.checklist.status;

export default checklistSlice.reducer;
