import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: 'mytokenhere',
    userId: null,
    tokenExpired: false
  },
  reducers: {
    authToken: (state, action) => {
      const token = action.payload;
      state.token = token;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    }
  }
});

export const Authenticated = (state) => state.authSlice.token !== null;

export const { authToken, setTokenExpired, setUserId } = authSlice.actions;

export default authSlice.reducer;
