import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {},
  saasInvoiceDetail: {}
};

export const solarElectricianSlice = createSlice({
  name: 'solarElectrician',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = action.payload;
    },
    addSaasInvoiceDetail: (state, action) => {
      state.saasInvoiceDetail = action.payload;
    },
    resetCreateSolarElectrician: (state) => {
      return initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addSaasInvoiceDetail,
  resetCreateSolarElectrician
} = solarElectricianSlice.actions;

export default solarElectricianSlice.reducer;
