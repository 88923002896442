import { Loader } from '@progress/kendo-react-indicators';
import React from 'react';

const SpinnerLoader = () => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <Loader type='converging-spinner' />
    </div>
  );
};

export default SpinnerLoader;
