import { useState } from 'react';
// Replace with actual import path
import { useSelector } from 'react-redux';

import TermsAndPrivacyService from 'services/terms-and-policy';

import showToast from 'utils/notificationToast/notificationToast';

const usePrivacy = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPrivacyPolicy = async (handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await TermsAndPrivacyService.getPrivacyPolicy();
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to get privacy policy');
        showToast('Unable to get privacy policy', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to get privacy policy');
      showToast('Unable to get privacy policy', 'error');
    } finally {
      setLoading(false);
    }
  };
  const getTermsAndConditions = async (handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await TermsAndPrivacyService.getTermsAndConditions();
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to get terms & conditions');
        showToast('Unable to get terms & conditions', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to get terms & conditions');
      showToast('Unable to get terms & conditions', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getTradingTermsAndConditions = async (handleSuccess) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await TermsAndPrivacyService.getTradingTermsAndConditions();
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        setError(resp.message || 'Unable to get terms & conditions');
        showToast('Unable to get terms & conditions', 'error');
      }
    } catch (error) {
      setError(error.message || 'Unable to get terms & conditions');
      showToast('Unable to get terms & conditions', 'error');
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, getPrivacyPolicy, getTermsAndConditions, getTradingTermsAndConditions };
};

export default usePrivacy;
