import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountManagerReducer from './accountManger/accountManagerSlice';
import editAccountManagerReducer from './accountManger/edit-account-manager-slice';
import authReducer from './auth/authSlice';
import ChecklistReducer from './checklist/checklist-slice';
import companyStaffReducer from './companyStaff/companyStaff-slice';
import editCompanyStaffReducer from './companyStaff/edit-company-staff-slice';
import editComplianceOfficerReducer from './compliance-officer/edit-compliance-officer-slice';
import createUserReducer from './create-user';
import editUserReducer from './edit-user';
import selectedItemSlice from './fco-group/selectedItem-slice';
import editResellerReducer from './reseller/edit-reseller-slice';
import resellerReducer from './reseller/reseller-slice';
import roleFeaturesReducer from './role-features';
import roleReducer from './role/role-slice';
import sequenceReducer from './sequence-number/sequence-slice';
import editSolarElectricianReducer from './solar-electrician/edit-solar-electrician-slice';
import solarElectricianReducer from './solar-electrician/solar-electrician-slice';
import editSolarCompanyReducer from './solarCompany/edit-solar-company-slice';
import solarCompanyReducer from './solarCompany/solarCompany-slice';
import subMenuReducer from './subMenu/submenuSlice';
import editSuperAdminReducer from './super-admin/edit-super-admin-slice';
import superAdmin from './super-admin/superAdminSlice';
import userDocumentReducer from './user-document-status';
import userDropdownsSlice from './user-dropdowns/user-dropdowns-slice';
import userProfileReducer from './user-profile';
import userTypeReducer from './user/user-type-slice';
import userReducer from './user/userSlice';

const persistConfig = {
  key: 'root',
  storage,
  // Add the reducers you want to persist
  whitelist: [
    'auth',
    'user',
    // "userProfile",
    'userType',
    'role',
    'userDropdowns',
    // "sequence",
    // "editUser",
    // "selectedItem",
    // "checklist",
    'roleFeatures'
    // "submenu",
  ]
};

const rootReducer = combineReducers({
  superAdmin,
  editSuperAdmin: editSuperAdminReducer,
  reseller: resellerReducer,
  editReseller: editResellerReducer,
  accountManager: accountManagerReducer,
  editAccountManager: editAccountManagerReducer,
  solarElectrician: solarElectricianReducer,
  editSolarElectrician: editSolarElectricianReducer,
  companyStaff: companyStaffReducer,
  editCompanyStaff: editCompanyStaffReducer,
  solarCompany: solarCompanyReducer,
  editSolarCompany: editSolarCompanyReducer,
  editComplianceOfficer: editComplianceOfficerReducer,
  auth: authReducer,
  user: userReducer,
  userDocumentStatus: userDocumentReducer,
  userProfile: userProfileReducer,
  userType: userTypeReducer,
  role: roleReducer,
  userDropdowns: userDropdownsSlice,
  sequence: sequenceReducer,
  createUser: createUserReducer,
  editUser: editUserReducer,
  selectedItem: selectedItemSlice,
  checklist: ChecklistReducer,
  roleFeatures: roleFeaturesReducer,
  submenu: subMenuReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/PURGE']
      }
    })
  ]
});

const persistor = persistStore(store);

export { store, persistor };
