import { Formik } from 'formik';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

import Footer from 'components/shared-components/footer/footer';
import InputField from 'components/shared-components/text-input/default-input-field';

import { updatePasswordSchema } from 'utils/validationSchema/auth';

const UpdatePasswordForm = ({ handleClose, handleSave, user = {} }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <div>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSave(values);
        }}
        validationSchema={updatePasswordSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <>
            <Row className='d-flex justify-content-center mb-4'>
              <Col className='mb-4' sm={9}>
                <InputField
                  required
                  type={!showPassword ? 'password' : 'text'}
                  name='oldPassword'
                  label='Old Password'
                  value={values.oldPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.oldPassword}
                  touched={touched.oldPassword}
                  showIcon={true}
                  icon={!showPassword ? <FaRegEyeSlash size={20} /> : <FaRegEye size={20} />}
                  handleShowPassword={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              </Col>
              <Col className='mb-4' sm={9}>
                <InputField
                  required
                  type={!showNewPassword ? 'password' : 'text'}
                  name='newPassword'
                  label='New Password'
                  value={values.newPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.newPassword}
                  touched={touched.newPassword}
                  showIcon={true}
                  icon={!showNewPassword ? <FaRegEyeSlash size={20} /> : <FaRegEye size={20} />}
                  handleShowPassword={() => {
                    setShowNewPassword(!showNewPassword);
                  }}
                />
              </Col>
              <Col className='mb-4' sm={9}>
                <InputField
                  required
                  type={!showConfirmPassword ? 'password' : 'text'}
                  name='confirmPassword'
                  label='Confirm Password'
                  value={values.confirmPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  showIcon={true}
                  icon={!showConfirmPassword ? <FaRegEyeSlash size={20} /> : <FaRegEye size={20} />}
                  handleShowPassword={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                />
              </Col>
            </Row>
            <Footer handleClose={handleClose} handleSubmit={handleSubmit} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePasswordForm;
