import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {
    driverLicenseDocuments: [],
    accreditationDocuments: [],
    insuranceDocuments: [],
    proofOfBusinessDocuments: [],
    signatureDocuments: []
  },
  installerAndDesigner: {
    pvd: {
      physicalAddress: {},
      poBoxAddress: {}
    },
    swh: {
      physicalAddress: {},
      poBoxAddress: {}
    }
  },
  saasInvoiceDetail: {},
  complianceStatus: {}
};

export const editSolarCompanySlice = createSlice({
  name: 'editSolarCompany',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        ...action.payload
      };
    },
    addDriverLicenseDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        driverLicenseDocuments: action.payload
      };
    },
    addAccreditationDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        accreditationDocuments: action.payload
      };
    },
    addInsuranceDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        insuranceDocuments: action.payload
      };
    },
    addProofOfBusinessDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        proofOfBusinessDocuments: action.payload
      };
    },
    addSignatureDocuments: (state, action) => {
      state.accountDetail = {
        ...state.accountDetail,
        signatureDocuments: action.payload
      };
    },
    addPvdInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          ...action.payload
        }
      };
    },
    addPvdPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          physicalAddress: action.payload
        }
      };
    },
    addPvdPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          ...state.installerAndDesigner.pvd,
          poBoxAddress: action.payload
        }
      };
    },

    addSwhInstallerDetail: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          ...action.payload
        }
      };
    },
    addSwhPhysicalAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          physicalAddress: action.payload
        }
      };
    },
    addSwhPoBoxAddress: (state, action) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          ...state.installerAndDesigner.swh,
          poBoxAddress: action.payload
        }
      };
    },
    resetPvd: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        pvd: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    resetSwh: (state) => {
      state.installerAndDesigner = {
        ...state.installerAndDesigner,
        swh: {
          physicalAddress: {},
          poBoxAddress: {}
        }
      };
    },
    addInvoiceDetail: (state, action) => {
      state.saasInvoiceDetail = action.payload;
    },
    addComplianceStatus: (state, action) => {
      state.complianceStatus = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addDriverLicenseDocuments,
  addAccreditationDocuments,
  addInsuranceDocuments,
  addProofOfBusinessDocuments,
  addSignatureDocuments,
  addPvdInstallerDetail,
  addPvdPhysicalAddress,
  addPvdPoBoxAddress,
  addSwhInstallerDetail,
  addSwhPhysicalAddress,
  addSwhPoBoxAddress,
  addInvoiceDetail,
  resetPvd,
  resetSwh,
  addComplianceStatus
} = editSolarCompanySlice.actions;

export default editSolarCompanySlice.reducer;
