import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetail: {},
  addressDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  accountDetail: {},
  wholesalerDetail: {
    physicalAddress: {},
    poBoxAddress: {}
  },
  saasInvoiceDetail: {}
};

export const editResellerSlice = createSlice({
  name: 'editReseller',
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    addAddressDetail: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        ...action.payload
      };
    },
    addAddressPhysicalAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        physicalAddress: action.payload
      };
    },
    addAddressPoBoxAddress: (state, action) => {
      state.addressDetail = {
        ...state.addressDetail,
        poBoxAddress: action.payload
      };
    },
    addAccountDetail: (state, action) => {
      state.accountDetail = action.payload;
    },
    addWholeSalerDetail: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        ...action.payload
      };
    },
    addWholeSalerPhysicalAddress: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        physicalAddress: action.payload
      };
    },
    addWholeSalerPoBoxAddress: (state, action) => {
      state.wholesalerDetail = {
        ...state.wholesalerDetail,
        poBoxAddress: action.payload
      };
    },
    addInvoiceDetail: (state, action) => {
      state.saasInvoiceDetail = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  addPersonalDetail,
  addAddressDetail,
  addAddressPhysicalAddress,
  addAddressPoBoxAddress,
  addAccountDetail,
  addWholeSalerDetail,
  addWholeSalerPhysicalAddress,
  addWholeSalerPoBoxAddress,
  addInvoiceDetail
} = editResellerSlice.actions;

export default editResellerSlice.reducer;
