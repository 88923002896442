import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProfileIconCircle } from 'assets/svg';

import useAuth from 'hooks/use-auth';

import menuItems from 'utils/navbarItems/navbarItems';

import LoaderModal from '../modal-loader/modal-loader';
import ProfileModal from '../profile-modal';
import styles from './header.module.css';
import UpdatePasswordModal from './update-password-modal';

const Header = () => {
  const route = window.location.pathname;

  const user = useSelector((state) => state?.user?.user);

  const [pathTitle, setPathTitle] = useState('Dashboard');
  const [showProfileModal, setShowProfileModal] = useState(false);

  const [showPasswordModal, setShowPasswordModal] = React.useState(false);

  const { loading, updatePassword } = useAuth();

  const pathTitleHandler = () => {
    menuItems.map((item) => {
      if (item.link === route || item.link === '/' + route.split('/')[1]) {
        setPathTitle(item.label);
      } else {
        if (item?.children?.length > 0) {
          item?.children.map((child) => {
            if (child.link === route) {
              setPathTitle(child.label);
            }
          });
        }
      }
    });
    if (route?.includes('profile')) {
      setPathTitle('Profile');
    }
  };

  const handleUpdatePassword = (values) => {
    updatePassword(values, () => {
      setShowPasswordModal(false);
    });
  };

  useEffect(() => {
    if (route === '/dashboard') {
      setPathTitle('Dashboard');
    } else if (menuItems.length > 0) {
      pathTitleHandler();
    }
  }, [route]);

  return (
    <>
      <div className={`${styles.bgColor} d-flex justify-content-between `}>
        <LoaderModal loading={loading} />
        <UpdatePasswordModal
          show={showPasswordModal}
          handleClose={() => setShowPasswordModal(false)}
          handleSave={(values) => {
            handleUpdatePassword(values);
          }}
        />

        <h1 className={styles.createUserText}> {pathTitle} </h1>

        <div
          role='button'
          onClick={() => setShowProfileModal(true)}
          sm='auto'
          className={`${styles.notificationButton} p-auto d-flex justify-content-center`}
        >
          {user?.profileImageURL ? (
            <img
              style={{ borderRadius: '50%' }}
              width={45}
              height={45}
              src={user?.profileImageURL}
              alt='profile'
            />
          ) : (
            <ProfileIconCircle />
          )}
        </div>
      </div>
      <ProfileModal
        showProfileModal={showProfileModal}
        handlePasswordModal={() => setShowPasswordModal(true)}
        setShowProfileModal={setShowProfileModal}
      />
    </>
  );
};

export default Header;
