import { Button } from '@progress/kendo-react-buttons';
import React from 'react';

import ButtonSpinner from '../button-loader/Spinner';

const CustomButton = ({
  primary = true,
  secondary = false,
  title,
  handleClick,
  props,
  type,
  borderRadius = '',
  loading,
  className = '',
  disabled = false
}) => {
  return (
    <>
      {primary ? (
        <Button
          disabled={disabled}
          type={type}
          {...props}
          themeColor={secondary ? 'secondary' : 'primary'}
          onClick={handleClick}
          className={`${className}`}
          style={{ borderRadius }}
        >
          {loading ? (
            <ButtonSpinner animation={'border'} variant={'light'} width={'20px'} height={'20px'} />
          ) : (
            title
          )}
        </Button>
      ) : (
        <Button
          {...props}
          onClick={(event) => {
            event.preventDefault();
            handleClick(event);
          }}
          type={type}
        >
          {title}
        </Button>
      )}
    </>
  );
};

export default CustomButton;
