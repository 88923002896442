import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roleList: []
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    addRoleList: (state, action) => {
      state.roleList = action.payload;
    }
  }
});

export const { addRoleList } = roleSlice.actions;
export default roleSlice.reducer;
