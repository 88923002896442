export const superAdminUserTypes = [
  {
    id: 1,
    name: 'Super Admin'
  },
  {
    id: 2,
    name: 'Auditor'
  },
  {
    id: 3,
    name: 'Reseller'
  },
  {
    id: 4,
    name: 'Customer Success Manager'
  },
  {
    id: 5,
    name: 'Solar Retailer'
  },
  {
    id: 6,
    name: 'Retailer Staff'
  },
  {
    id: 7,
    name: 'Trades Specialist'
  }
];

export const coUserTypes = [
  {
    id: 3,
    name: 'Reseller'
  },
  {
    id: 4,
    name: 'Customer Success Manager'
  },
  {
    id: 5,
    name: 'Solar Retailer'
  },
  {
    id: 6,
    name: 'Retailer Staff'
  },
  {
    id: 7,
    name: 'Trades Specialist'
  }
];

export const accountManagerUserTypes = [
  {
    id: 3,
    name: 'Reseller'
  },
  {
    id: 5,
    name: 'Solar Retailer'
  },
  {
    id: 6,
    name: 'Retailer Staff'
  },
  {
    id: 7,
    name: 'Trades Specialist'
  }
];

export const resellerUserTypes = [
  {
    id: 5,
    name: 'Solar Retailer'
  },
  {
    id: 6,
    name: 'Retailer Staff'
  },
  {
    id: 7,
    name: 'Trades Specialist'
  }
];

export const solarCompanyUserTypes = [
  {
    id: 6,
    name: 'Retailer Staff'
  },
  {
    id: 7,
    name: 'Trades Specialist'
  }
];

export const companyStaffUserTypes = [
  {
    id: 6,
    name: 'Retailer Staff'
  },
  {
    id: 7,
    name: 'Trades Specialist'
  }
];

export const solarElectricianUserTypes = [
  {
    id: 7,
    name: 'Trades Specialist'
  }
];
