import { APP_ROUTES } from 'config/app-routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PrivacyPolicy from 'components/privacy-policy';
import CustomButton from 'components/shared-components/custom-button';
import LoaderModal from 'components/shared-components/modal-loader/modal-loader';
import TermsAndConditions from 'components/terms-and-conditions';

import useUser from 'hooks/use-user';

const TermsAndPrivacyPolicy = () => {
  const token = useSelector((state) => state.auth.token);

  const navigate = useNavigate();

  const { loading, createUserTermsAndConditions } = useUser();

  const handleAccept = () => {
    createUserTermsAndConditions(() => {
      navigate(`${APP_ROUTES.private.AUTHENTICATION_CALLBACK}?token=${token}`);
    });
  };

  const handleLogout = async () => {
    try {
      await localStorage.clear();
      navigate(APP_ROUTES.private.HOME);
    } catch (error) {}
  };

  if (loading) {
    return <LoaderModal loading={loading} />;
  }

  return (
    <div style={{ paddingBottom: '30px' }}>
      <TermsAndConditions />
      <h1 style={{ paddingLeft: '30px' }}>Privacy Policy</h1>
      <PrivacyPolicy />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem'
        }}
      >
        {token && (
          <CustomButton
            title={'I accept the terms and conditions and privacy policy'}
            handleClick={handleAccept}
          />
        )}

        <CustomButton title={'Go back'} handleClick={handleLogout} />
      </div>
    </div>
  );
};

export default TermsAndPrivacyPolicy;
