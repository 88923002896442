import { APP_ROUTES } from 'config/app-routes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { operationId } from 'constants/index';

const useCheckAccess = (featureId, navigation = true) => {
  const navigate = useNavigate();
  const roleAccess = useSelector((state) => state.user?.user?.rolesWithAccess);

  const [access, setAccess] = useState({
    create: false,
    read: false,
    update: false,
    delete: false,
    assign: false
  });

  const getAccessInfo = (access, operationId) => {
    if (access?.length === 0 || !access) return null;
    return access.find((item) => item.operationID === operationId);
  };

  useEffect(() => {
    if (roleAccess) {
      const accessInfo = roleAccess.roleFeatureAccess?.find((role) => role.featureID === featureId);

      const accessArray = accessInfo?.access;
      if ((getAccessInfo(accessArray, operationId.Read)?.value ?? false) === false && navigation) {
        navigate(APP_ROUTES.private.ACCESS_DENIED, { replace: true });
      }

      setAccess({
        create: getAccessInfo(accessArray, operationId.Create)?.value ?? false,
        update: getAccessInfo(accessArray, operationId.Update)?.value ?? false,
        read: getAccessInfo(accessArray, operationId.Read)?.value ?? false,
        delete: getAccessInfo(accessArray, operationId.Delete)?.value ?? false,
        assign: getAccessInfo(accessArray, operationId.Assign)?.value ?? false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return access;
};

export default useCheckAccess;
