import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const roleFeatures = createSlice({
  name: 'roleFeatures',
  initialState,
  reducers: {
    addRoleFeatures: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { addRoleFeatures } = roleFeatures.actions;
export default roleFeatures.reducer;
