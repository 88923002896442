import CustomModal from 'components/shared-components/modal/custom-modal';

import UpdatePasswordForm from './update-password-form';

const UpdatePasswordModal = ({ show, handleClose, handleSave }) => {
  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        title='Update Password'
        body={<UpdatePasswordForm handleClose={handleClose} handleSave={handleSave} />}
      />
    </>
  );
};

export default UpdatePasswordModal;
